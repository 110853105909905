import { Injectable } from '@angular/core';
import {
  ProfileApi,
  ProfileDetailsResult,
} from '@idealsupply/ngclient-webservice-identity-server';
import {
  BehaviorSubject,
  distinctUntilChanged,
  firstValueFrom,
  map,
  Observable,
  shareReplay,
} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CurrentUserService {
  private _currentUserSubject: BehaviorSubject<
    ProfileDetailsResult | undefined
  > = new BehaviorSubject<ProfileDetailsResult | undefined>(undefined);
  public readonly currentUser$ = this._currentUserSubject.asObservable();
  public readonly authenticated$ = this.currentUser$.pipe(
    map((u) => !!u),
    distinctUntilChanged(),
    shareReplay(1)
  );

  private _userPromise?: Promise<ProfileDetailsResult>;
  private _user?: ProfileDetailsResult;
  constructor(private readonly api: ProfileApi) {}

  public get currentUser(): Promise<ProfileDetailsResult> {
    if (!this._userPromise) {
      this.loadUser();
    }
    return this._userPromise!;
  }

  public loadUser(): Promise<ProfileDetailsResult> {
    this._userPromise = new Promise(async (res, rej) => {
      try {
        this._user = await firstValueFrom(this.api.getProfile());
        this._currentUserSubject.next(this._user);
        res(this._user);
      } catch (e) {
        rej(e);
      }
    });
    return this._userPromise;
  }

  public async isAuthenticated(): Promise<boolean> {
    try {
      const user = await this.currentUser;
      return !!user;
    } catch (e) {
      console.error(e);
      return false;
    }
  }
}
