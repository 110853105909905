import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  LoginApi,
  LogoutContextResult,
} from '@idealsupply/ngclient-webservice-identity-server';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['../common/pod-page.scss', './logout.component.scss'],
})
export class LogoutComponent implements OnInit {
  public logoutContext: LogoutContextResult;
  constructor(
    private readonly route: ActivatedRoute,
    private readonly loginApi: LoginApi
  ) {
    this.logoutContext = route.snapshot.data['logoutContext'];
  }

  public async ngOnInit(): Promise<void> {
    if (!this.logoutContext.showSignoutPrompt) {
      await this.doLogout();
    }
  }

  private async doLogout() {
    await firstValueFrom(this.loginApi.logout());
    window.location.href = this.logoutContext.postLogoutRedirectUri!;
  }

  public async logoutClicked() {
    await this.doLogout();
  }
}
