<svg
  xmlns:dc="http://purl.org/dc/elements/1.1/"
  xmlns:cc="http://creativecommons.org/ns#"
  xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
  xmlns:svg="http://www.w3.org/2000/svg"
  xmlns="http://www.w3.org/2000/svg"
  viewBox="0 0 781.92 164.16"
  version="1.1"
>
  <defs>
    <style>
      .cls-1,
      .cls-2 {
        fill: currentColor;
      }
      .cls-2 {
        fill-rule: evenodd;
      }
    </style>
  </defs>
  <title>Ideal Supply Inc.</title>
  <path class="cls-1" d="M193.59,38.22h21.12l-11.15,70.39H182.44Z"></path>
  <path
    class="cls-1"
    d="M255,36.21h19.11l-11.46,72.4H224.08a10.38,10.38,0,0,1-10.41-12.37l4.44-28c1.08-6.74,7.59-12.37,14.32-12.37h19.43Zm-22,59.14H245.6l4.15-26.26H237.08Z"
  ></path>
  <path
    class="cls-1"
    d="M276.85,68.19c1.07-6.74,7.59-12.37,14.33-12.37h23.13a10.39,10.39,0,0,1,10.42,12.37L323.64,75c-1.06,6.73-7.59,12.36-14.33,12.36H292.92L292,93.33h29.78l-8.47,15.28H282.81a10.37,10.37,0,0,1-10.4-12.37Zm18.81,1.91-1,6.63h10.65l1.05-6.63Z"
  ></path>
  <path
    class="cls-1"
    d="M327.39,89.4C328.47,82.66,335,77,341.72,77h16.41l.93-5.93H330.29l8.46-15.28h29.47a10.39,10.39,0,0,1,10.42,12.37l-6.4,40.42H336.72a10.38,10.38,0,0,1-10.41-12.37Zm28,4.93,1-6.63H345.78l-1.06,6.63Z"
  ></path>
  <path
    class="cls-1"
    d="M392.64,108.61a10.38,10.38,0,0,1-10.4-12.37l9.5-60h19.12l-8.77,55.18h12l-8.73,17.22Z"
  ></path>
  <path
    class="cls-1"
    d="M412.53,108.61l8.77-17.3h30.57l1.67-10.45H432.2c-7.13,0-11.46-5.13-10.32-12.27l2.87-18.1A14.34,14.34,0,0,1,439,38.22h43.47l-8.78,17.3H445.08L443.42,66h21.32c7.14,0,11.46,5.13,10.34,12.27l-2.87,18.11A14.34,14.34,0,0,1,458,108.61Z"
  ></path>
  <path
    class="cls-1"
    d="M486.67,55.82h19.09l-5.92,37.51H512.5l6-37.51h19.11l-8.36,52.79H490.67a10.37,10.37,0,0,1-10.4-12.37Z"
  ></path>
  <path
    class="cls-1"
    d="M553.71,127.92H534.59L546,55.82h38.54A10.39,10.39,0,0,1,595,68.19l-4.44,28.05c-1.07,6.75-7.59,12.37-14.33,12.37H556.76Zm17.83-32.57,4.16-26.26H563l-4.15,26.26Z"
  ></path>
  <path
    class="cls-1"
    d="M612.44,127.92H593.33l11.42-72.1h38.52a10.39,10.39,0,0,1,10.42,12.37l-4.45,28.05c-1.06,6.75-7.58,12.37-14.32,12.37H615.5Zm17.83-32.57,4.16-26.26H621.75l-4.14,26.26Z"
  ></path>
  <path
    class="cls-1"
    d="M667.31,108.61a10.38,10.38,0,0,1-10.42-12.37l9.52-60h19.11l-9,57.12h8.65l-8.47,15.28Z"
  ></path>
  <path
    class="cls-1"
    d="M736.52,115.56c-1.08,6.74-7.6,12.36-14.35,12.36H682.25l7.88-14.28H717.7l1-6H699.25a10.4,10.4,0,0,1-10.42-12.38l6.24-39.42h19.11l-5.78,36.5h12.68l5.78-36.5H746Z"
  ></path>
  <path
    class="cls-2"
    d="M69.88,106.76a33.93,33.93,0,1,1,0-67.86h5.91V58.53H69.88a14.29,14.29,0,0,0,0,28.58h5.91v19.65Zm39.84,0a33.93,33.93,0,0,0,0-67.86h-5.91V58.53h5.91a14.29,14.29,0,1,1,0,28.58h-5.91v19.65ZM80.66,36.21H98.93V61.08H80.66V36.21Zm0,48.36H98.93v24.88H80.66V84.57Z"
  ></path>
</svg>
