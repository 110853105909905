import { Selector, StateContext } from '@ngxs/store';
import produce, { Draft } from 'immer';

export interface AsyncStateModel {
  loading: boolean;
  busy: boolean;
}

export abstract class AsyncState<TStateModel extends AsyncStateModel> {
  public static DEFAULTS: AsyncStateModel = {
    loading: false,
    busy: false,
  };

  @Selector()
  public static loading(state: AsyncStateModel) {
    return state.loading;
  }
  @Selector()
  public static busy(state: AsyncStateModel) {
    return state.loading || state.busy;
  }

  protected setBusy(ctx: StateContext<TStateModel>, busy: boolean) {
    ctx.setState(
      produce((draft: Draft<TStateModel>) => {
        draft.busy = busy;
      })
    );
  }
  protected setLoading(ctx: StateContext<TStateModel>, loading: boolean) {
    ctx.setState(
      produce((draft: Draft<TStateModel>) => {
        draft.loading = loading;
      })
    );
  }
}
