import { Injectable } from '@angular/core';
import {
  RolesApi,
  UserRoleModel,
} from '@idealsupply/ngclient-webservice-identity-server';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import produce from 'immer';
import { retry, tap } from 'rxjs';
import { AsyncState, AsyncStateModel } from '../common/async-state-model';
import { LoadUserRoles } from './user-roles.actions';

export interface UserRolesStateModel extends AsyncStateModel {
  roles: UserRoleModel[];
}

@State<UserRolesStateModel>({
  name: 'userRoles',
  defaults: {
    ...AsyncState.DEFAULTS,
    roles: [],
  },
})
@Injectable()
export class UserRolesState extends AsyncState<UserRolesStateModel> {
  constructor(private readonly roleApi: RolesApi) {
    super();
  }

  @Selector()
  public static override loading(state: AsyncStateModel) {
    return super.loading(state);
  }

  @Selector()
  public static override busy(state: AsyncStateModel) {
    return super.busy(state);
  }

  @Selector()
  public static roles(state: UserRolesStateModel) {
    return state.roles;
  }

  @Action(LoadUserRoles, { cancelUncompleted: true })
  public search(ctx: StateContext<UserRolesStateModel>) {
    this.setLoading(ctx, true);
    return this.roleApi.getRoles().pipe(
      retry(3),
      tap({
        next: (result) => {
          ctx.setState(
            produce((draft) => {
              draft.roles = result;
            })
          );
        },
        complete: () => {
          this.setLoading(ctx, false);
        },
      })
    );
  }
}
