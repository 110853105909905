<app-logo color="primary"></app-logo>

<div class="instructions">
  <div *ngIf="instructionState === 'initial'">
    Enter your email and password to login to your account.
  </div>
  <div *ngIf="instructionState === 'submit'">
    Click the <i>Login</i> button to login to your account.
  </div>
  <div
    *ngIf="instructionState === 'error'"
    class="error"
    [innerHTML]="loginFailedMessage"
  ></div>
</div>

<mat-card>
  <mat-card-title>Login</mat-card-title>
  <mat-card-content>
    <form [formGroup]="form" (ngSubmit)="handleSubmit()">
      <mat-form-field appearance="outline" color="accent">
        <mat-label>Email Address</mat-label>
        <input
          matInput
          name="username"
          formControlName="username"
          required
          autofocus
        />
        <mat-error>Email address is required</mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline" color="accent">
        <mat-label>Password</mat-label>
        <input
          matInput
          [type]="showPassword ? 'text' : 'password'"
          name="password"
          formControlName="password"
          required
        />
        <mat-icon matSuffix (click)="showPassword = !showPassword">{{
          showPassword ? "visibility" : "visibility_off"
        }}</mat-icon>
        <mat-error>Password is required</mat-error>
      </mat-form-field>

      <button mat-raised-button color="primary">Login</button>
      <div class="two-button">
        <button
          mat-button
          color="accent"
          type="button"
          routerLink="/password/recovery"
          [queryParams]="{ returnUrl: loginContext.returnUrl }"
        >
          Forgot Password?
        </button>
        <button
          mat-button
          color="accent"
          type="button"
          routerLink="/signup"
          [queryParams]="{ returnUrl: loginContext.returnUrl }"
        >
          Create Account
        </button>
      </div>
    </form>
  </mat-card-content>
</mat-card>
