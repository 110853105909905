import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Resolve,
  RouterStateSnapshot,
} from '@angular/router';
import {
  LoginApi,
  LoginContextResult,
} from '@idealsupply/ngclient-webservice-identity-server';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoginContextResolver implements Resolve<LoginContextResult> {
  constructor(private readonly service: LoginApi) {}
  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<LoginContextResult> {
    return this.service.getLoginContext(route.queryParams['returnUrl']);
  }
}
