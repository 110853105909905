import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LogoutContextResolver } from './logout-context.resolver';
import { LogoutComponent } from './logout.component';

const routes: Routes = [
  {
    path: 'logout',
    component: LogoutComponent,
    resolve: {
      logoutContext: LogoutContextResolver,
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class LogoutRoutingModule {}
