import { Injectable, Type } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanLoad,
  Route,
  Router,
  RouterStateSnapshot,
  UrlSegment,
  UrlTree,
} from '@angular/router';
import { ProfileDetailsResult } from '@idealsupply/ngclient-webservice-identity-server';
import { CurrentUserService } from './../current-user.service';
import { AuthenticatedGuard } from './authenticated.guard';

export class RolesGuard implements CanActivate, CanLoad {
  public static anyOf(...roles: string[]): Type<RolesGuard> {
    @Injectable({
      providedIn: 'root',
    })
    class AnyOfRoleCheck extends RolesGuard {
      constructor(
        userService: CurrentUserService,
        router: Router,
        authenticatedGuard: AuthenticatedGuard
      ) {
        super(userService, router, authenticatedGuard);
        this.check = async (user: ProfileDetailsResult) => {
          const uRoles = user.roles ?? [];
          for (const role of roles) {
            if (uRoles.includes(role)) {
              return true;
            }
          }
          return false;
        };
      }
    }
    return AnyOfRoleCheck;
  }

  public static allOf(...roles: string[]): Type<RolesGuard> {
    @Injectable({
      providedIn: 'root',
    })
    class AllOfRoleCheck extends RolesGuard {
      constructor(
        userService: CurrentUserService,
        router: Router,
        authenticatedGuard: AuthenticatedGuard
      ) {
        super(userService, router, authenticatedGuard);
        this.check = async (user: ProfileDetailsResult) => {
          const uRoles = user.roles ?? [];
          for (const role of roles) {
            if (!uRoles.includes(role)) {
              return false;
            }
          }
          return true;
        };
      }
    }
    return AllOfRoleCheck;
  }

  protected check: (user: ProfileDetailsResult) => Promise<boolean> =
    async () => true;

  protected constructor(
    private readonly userService: CurrentUserService,
    private readonly router: Router,
    private readonly authenticatedGuard: AuthenticatedGuard
  ) {}

  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean | UrlTree> {
    const user = await this.userService.currentUser;
    var can = await this.check(user);
    if (!can) {
      if (!user) {
        console.log('not logged!!');
        return false;
      } else {
        console.log('access denied');
        return this.router.createUrlTree(['/access-denied']);
      }
    }

    return can;
  }
  async canLoad(
    route: Route,
    segments: UrlSegment[]
  ): Promise<boolean | UrlTree> {
    const user = await this.userService.currentUser;
    var can = await this.check(user);
    if (!can) {
      if (!user) {
        console.log('not logged!!');
        return false;
      } else {
        console.log('access denied');
        return this.router.createUrlTree(['/access-denied']);
      }
    }
    return can;
  }
}
