import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanLoad,
  Route,
  Router,
  RouterStateSnapshot,
  UrlSegment,
  UrlTree,
} from '@angular/router';
import { CurrentUserService } from './../current-user.service';

@Injectable({
  providedIn: 'root',
})
export class AuthenticatedGuard implements CanActivate, CanLoad {
  constructor(
    private readonly userService: CurrentUserService,
    private readonly router: Router
  ) {}
  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean | UrlTree> {
    const auth = await this.userService.isAuthenticated();
    if (!auth) {
      const returnUrl = this.router
        .getCurrentNavigation()
        ?.finalUrl?.toString();
      return this.router.createUrlTree(['/login'], {
        queryParams: {
          returnUrl,
        },
      });
    }
    return auth;
  }
  async canLoad(
    route: Route,
    segments: UrlSegment[]
  ): Promise<boolean | UrlTree> {
    const auth = await this.userService.isAuthenticated();
    if (!auth) {
      const returnUrl = this.router
        .getCurrentNavigation()
        ?.extractedUrl.toString();
      return this.router.createUrlTree(['/login'], {
        queryParams: {
          returnUrl,
        },
      });
    }
    return auth;
  }
}
