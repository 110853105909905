import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Resolve,
  RouterStateSnapshot,
} from '@angular/router';
import {
  LoginApi,
  LogoutContextResult,
} from '@idealsupply/ngclient-webservice-identity-server';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LogoutContextResolver implements Resolve<LogoutContextResult> {
  constructor(private readonly service: LoginApi) {}
  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<LogoutContextResult> {
    return this.service.getLogoutContext(route.queryParams['logoutId']);
  }
}
