import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthenticatedGuard } from './common/guards/authenticated.guard';
import { RolesGuard } from './common/guards/roles.guard';
import { LoginModule } from './login/login.module';
import { LogoutModule } from './logout/logout.module';

const routes: Routes = [
  {
    path: 'signup',
    loadChildren: () =>
      import('./signup/signup.module').then((m) => m.SignupModule),
  },
  {
    path: 'verify/:token',
    redirectTo: 'signup/verify/:token',
  },
  {
    path: 'username/verify/:token',
    redirectTo: 'profile/verify-username-change/:token',
  },
  {
    path: 'password',
    loadChildren: () =>
      import('./password-recovery/password-recovery.module').then(
        (m) => m.PasswordRecoveryModule
      ),
  },
  {
    path: 'profile',
    loadChildren: () =>
      import('./profile/profile.module').then((m) => m.ProfileModule),
  },
  {
    path: 'consent',
    loadChildren: () =>
      import('./consent/consent.module').then((m) => m.ConsentModule),
  },
  {
    path: 'admin',
    canLoad: [AuthenticatedGuard],
    canActivate: [RolesGuard.anyOf('admin', 'moderator')],
    loadChildren: () =>
      import('./admin/admin.module').then((m) => m.AdminModule),
  },
  {
    path: 'access-denied',
    loadChildren: () =>
      import('./access-denied/access-denied.module').then(
        (m) => m.AccessDeniedModule
      ),
  },
  {
    path: '',
    loadChildren: () =>
      import('./dashboard/dashboard.module').then((m) => m.DashboardModule),
  },
];

@NgModule({
  imports: [LoginModule, LogoutModule, RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
