<app-logo color="primary"></app-logo>
<mat-card *ngIf="!logoutContext.showSignoutPrompt">
  <mat-card-title>Logging Out</mat-card-title>
  <mat-card-content> Logging you out... </mat-card-content>
</mat-card>
<mat-card *ngIf="logoutContext.showSignoutPrompt">
  <mat-card-title>Logout</mat-card-title>
  <mat-card-content> Click the button below to log out. </mat-card-content>
  <mat-card-actions>
    <button mat-raised-button color="primary" (click)="logoutClicked()">
      Logout
    </button>
  </mat-card-actions>
</mat-card>
