import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {
  LoginApi,
  LoginContextResult,
  LoginRequest,
} from '@idealsupply/ngclient-webservice-identity-server';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['../common/pod-page.scss', './login.component.scss'],
})
export class LoginComponent implements OnInit {
  public loginContext: LoginContextResult;

  public instructionState: 'initial' | 'submit' | 'error' = 'initial';

  public showPassword: boolean = false;
  public loginFailedMessage: string = '';

  public parmas: any;

  public form = new FormGroup({
    username: new FormControl('', { validators: [Validators.required] }),
    password: new FormControl('', { validators: [Validators.required] }),
    remember: new FormControl(false),
  });

  constructor(
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly loginApi: LoginApi
  ) {
    this.loginContext = route.snapshot.data['loginContext'];
    this.parmas = route.snapshot.queryParams;

    this.form.statusChanges.subscribe((_) => {
      if (this.form.valid) {
        this.instructionState = 'submit';
      } else {
        this.instructionState = 'initial';
      }
      if (this.loginFailedMessage) {
        this.instructionState = 'error';
      }
    });
  }

  ngOnInit(): void {
    window.addEventListener('pageshow', async (event) => {
      if (event.persisted) {
        await firstValueFrom(this.loginApi.logout());
        window.location.reload();
      }
    });
  }

  async handleSubmit(): Promise<void> {
    if (this.form.valid) {
      const loginRequest = this.form.value as LoginRequest;
      try {
        this.loginFailedMessage = '';
        await firstValueFrom(this.loginApi.login(loginRequest));
        if (this.loginContext.returnUrl) {
          if (history.state.useRouter === true) {
            this.router.navigateByUrl(this.loginContext.returnUrl, {
              replaceUrl: true,
            });
          } else {
            window.location.replace(this.loginContext.returnUrl);
          }
        } else {
          this.router.navigate(['/']);
        }
      } catch (e) {
        this.instructionState = 'error';
        if (e instanceof HttpErrorResponse) {
          switch (e.status) {
            case 401:
              this.loginFailedMessage =
                'The email or password was incorrect. Please try again.';
              break;
            case 400:
              this.loginFailedMessage = 'Validation Error';
              break;
            default:
              this.loginFailedMessage = e.message;
              throw e;
          }
        } else if (e instanceof Error) {
          this.loginFailedMessage = e.message;
          throw e;
        } else {
          this.loginFailedMessage = 'An Unknown error has occurred';
          throw e;
        }
      }
    }
  }
}
