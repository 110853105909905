import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, tap } from 'rxjs';

@Injectable()
export class Http401Interceptor implements HttpInterceptor {
  public constructor(private readonly router: Router) {}

  public intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap({
        error: (err: any) => {
          /*if (err instanceof HttpErrorResponse) {
            if (err.status !== 401) {
              return;
            }
            const returnUrl = this.router
              .getCurrentNavigation()
              ?.finalUrl?.toString();
            this.router.navigate(['/login'], {
              queryParams: {
                returnUrl: returnUrl,
              },
              state: {
                useRouter: true,
              },
            });
          }*/
        },
      })
    );
  }
}
