import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanLoad,
  Route,
  Router,
  RouterStateSnapshot,
  UrlSegment,
  UrlTree,
} from '@angular/router';
import { CurrentUserService } from '../current-user.service';
import {
  LoginApi,
  LogoutContextResult,
} from '@idealsupply/ngclient-webservice-identity-server';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class EnsureLoggedOutGuard implements CanActivate, CanLoad {
  constructor(
    private readonly userService: CurrentUserService,
    private readonly loginApi: LoginApi,
    private readonly router: Router
  ) {}
  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean | UrlTree> {
    console.log('EnsureLoggedOutGuard.canActivate');
    const auth = await this.userService.isAuthenticated();
    if (auth) {
      await firstValueFrom(this.loginApi.logout());
      window.location.reload();
    }
    return true;
  }
  async canLoad(
    route: Route,
    segments: UrlSegment[]
  ): Promise<boolean | UrlTree> {
    const auth = await this.userService.isAuthenticated();
    if (auth) {
      await firstValueFrom(this.loginApi.logout());
      window.location.reload();
    }
    return true;
  }
}
